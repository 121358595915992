import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { UtilService } from './util.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl = environment.apiUrl + environment.version
  constructor(
    private http: HttpClient,
    private util: UtilService
  ) { }

  //club Api

  clubName() {
    return this.http.get(`${this.baseUrl}admin/club/clubName`).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("clubName", []))
    );
  }

  // end club Api



  // Start user work

  getClubUser(data) {
    const option = {
      params: data
    };
    return this.http.get(`${this.baseUrl}admin/clubUsers/getClubUser`, option).pipe(
      catchError(this.util.handleError("getUser", []))
    )
  }

  editUserInClub(id, data) {
    return this.http.post(`${this.baseUrl}admin/clubUsers/edit/${id}`, data).pipe(
      catchError(this.util.handleError("editClubUser", []))
    );
  }

  listAllUser(data) {
    const options: any = {
      params: data
    };
    return this.http.get(
      `${this.baseUrl}admin/users/list`, options)
      .pipe(
        map((response: any) => {
          if (data.isCSV) {
            return window.open(response.data);
          }
          return response;
        }),
        catchError(this.util.handleError('listUser', []))
      );
  }

  listOfUser(data) {
    const options = {
      params: data,
    }
    return this.http.get(`${this.baseUrl}admin/clubUsers/list`, options).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("agentTransaction", []))
    );
  }

  getUser(id) {
    return this.http.get(`${this.baseUrl}admin/clubUsers/view/${id}`).pipe(
      catchError(this.util.handleError('getUserById', []))
    );
  }

  getUserById(id) {
    return this.http.get(`${this.baseUrl}admin/users/view/${id}`).pipe(
      catchError(this.util.handleError('getUserById', []))
    );
  }

  // Game details of user
  
  listGameTransaction(data) {
    return this.http.get(`${this.baseUrl}admin/users/game/transaction/list`, { params: data }).pipe(
        catchError(this.util.handleError('listGameTransaction', []))
    );
}

  // user enter in club
  enterInClub(data) {
    return this.http.post(`${this.baseUrl}admin/clubUsers/addUser`, data).pipe(
      catchError(this.util.handleError("enterInClub", []))
    );
  }

  // End user work

  // start agent work

  //get agent by id
  getAgent(id) {
    return this.http.get(`${this.baseUrl}admin/agent/get/${id}`).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("getAgent", []))
    );
  }

  // add agent in club by clubOwner
  addAgent(data) {
    return this.http.post(`${this.baseUrl}admin/agent/`, data).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("addAgent", []))
    );
  }

  //edit by club owner
  editAgent(id, data) {
    return this.http.post(`${this.baseUrl}admin/agent/edit/${id}`, data).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("editAgent", []))
    );
  }

  //delete by club owner
  deleteAgent(id) {
    return this.http.delete(`${this.baseUrl}admin/agent/delete/${id}`).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("deleteClub", []))
    );
  }

  //get agent name
  agentName(data) {
    return this.http.post(`${this.baseUrl}admin/agent/agentName`, data).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("agentName", []))
    );
  }

  // list of agent in club
  getClubAgent(data) {
    const option = {
      params: data
    };
    return this.http.get(`${this.baseUrl}admin/agent/list`, option).pipe(
      catchError(this.util.handleError("getUser", []))
    )
  }

  agentCredit(data) {
    return this.http.post(`${this.baseUrl}admin/agent/creditChip`, data).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("agentCredit", []))
    );
  }

  agentDebit(data) {
    return this.http.post(`${this.baseUrl}admin/agent/debitChip`, data).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("agentCredit", []))
    );
  }

  agentTransaction(data: any) {
    const options: any = {
      params: data,
    };
    return this.http.get(`${this.baseUrl}admin/agent/transactionList`, options).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("agentTransaction", []))
    );
  }
}

