import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class RummuytableService {
  private baseUrl: string = environment.apiUrl + environment.version;
  constructor(private http: HttpClient, private util: UtilService) {
  }
  // list of table
  tableList(params) {
      return this.http.get
          (`${this.baseUrl}admin/clubTable/list`, { params }).pipe(
              catchError(this.util.handleError('tableList', []))
          )
  }

  // get table by id
  getTable(iTableId) {
      return this.http.get
          (`${this.baseUrl}admin/clubTable/getTable/${iTableId}`).pipe(
              catchError(this.util.handleError('getTable', []))
          )
  }

  // edit status all table 
  changeStatus(params, id) {
      return this.http.post
          (`${this.baseUrl}admin/clubTable/editTable/${id}`, params).pipe(
              catchError(this.util.handleError('changeStatus', []))
          )
  }

  changeAllTableStatus(data) {
  console.log('❖ - changeAllTableStatus - data', data)
      
    return this.http.post
        (`${this.baseUrl}admin/clubTable/changeAllTableStatus`, data).pipe(
            catchError(this.util.handleError('changeAllTableStatus', []))
        )
}
  // delete table
  deleteTable(id) {
      return this.http.delete
          (`${this.baseUrl}admin/clubTable/deleteTable/${id}`).pipe(
              catchError(this.util.handleError('deleteTable', []))
          )
  }



  // edit table
  edit(id, params) {
      return this.http.post
          (`${this.baseUrl}admin/clubTable/editTable/${id}`, params).pipe(
              catchError(this.util.handleError('edit', []))
          )
  }

  // creat table
  createTable(params) {
      return this.http.post
          (`${this.baseUrl}admin/clubTable/addTable`, params).pipe(
              catchError(this.util.handleError('createProto', []))
          )
  }

  // private handleError<T>(operation = 'operation', result?: any) {
  //     return (error: any): Observable<any> => {
  //         this.util.notifyError(error);
  //         return from(result);
  //     };
  // }
}
