export class Messages {
  'sEmail' = [
    { type: 'required', message: 'Email is required' },
    { type: 'email', message: 'Enter vaild email' }
  ]
  'sPassword' = [
    { type: 'required', message: 'Password is required' }
  ]
  'sOldPassword'=[
    { type: 'required', message: 'Old Password is required' }
  ]
  'sNewPassword'=[
    { type: 'required', message: 'New Password is required' } ,
    { type: 'pattern', message: 'Password must be at least 8 characters long.' }
  ]
  'sNewRetypedPassword'=[
    { type: 'required', message: 'Conform Password is required' } ,
   
  ]
}