import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor() { }

  public redeemStatus = [
    { value: '', name: 'All' },
    { value: 'pending', name: 'Pending' },
    { value: 'approved', name: 'Approved' },
    { value: 'rejected', name: 'Rejected' },
    // { value: 'canceled', name: 'Canceled' }
  ];

  public datatableRange = [
    { value: '5', name: '5' },
    { value: '10', name: '10' },
    { value: '50', name: '50' },
    { value: '100', name: '100' },
    { value: '500', name: '500' },
    { value: '1000', name: '1000' },
    { value: '2000', name: '2000' },
    { value: '3000', name: '3000' }
  ];

  public transactionStatus = [
    { value: '', name: 'All' },
    { value: 'failed', name: 'failed' },
    { value: 'SUCCESS', name: 'SUCCESS' },
    { value: 'approved', name: 'approved' },
    { value: 'pending', name: 'pending' },
    { value: 'ERROR', name: 'ERROR' },
    { value: 'refund-initiated', name: 'refund-initiated' },
    { value: 'refunded', name: 'refunded' }
  ];

  public userStatus = [
    { name: 'All', value: '' },
    { name: 'active', value: 'y' },
    { name: 'inactive', value: 'n' },
    // { name: 'deleted', value: 'd' }
  ];

  public tableRange = [
    // { value: "", name: "All" },
    { value: '2', name: '2' },
    // { value: '3', name: '3' },
    { value: '6', name: '6' },];

  public tableType = [
    { value: '', name: 'All' },
    { value: 'cash', name: 'cash' },
    { value: 'practice', name: 'practice' },
  ];
}
